<template>
  <div class="hot1">
    <div class="hotImg">
      <img id="hotImg" src="../assets/hot.jpg" alt="" />
      <!-- 关闭按钮 -->
      <div class="closeHot" @click="changeShowHot"></div>
      <!-- 直达按钮 -->
      <div class="direct" @click="directTo"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    let hotImg = document.querySelector("#hotImg");
    let close = document.querySelector(".closeHot");
    let direct = document.querySelector(".direct");
    // 414 *x =46
    // 892
    hotImg.onload = function () {
      // 关闭按钮的样式
      close.style.width = hotImg.clientWidth * 0.0483 + "px";
      close.style.height = hotImg.clientHeight * 0.0271 + "px";
      close.style.right = hotImg.clientWidth * 0.06 + "px";
      close.style.top = hotImg.clientHeight * 0.03 + "px";
      // 直达按钮的样式
      direct.style.width = hotImg.clientWidth * 0.777 + "px";
      direct.style.height = hotImg.clientHeight * 0.057 + "px";
      direct.style.right = hotImg.clientWidth * 0.111 + "px";
      direct.style.top = hotImg.clientHeight * 0.875 + "px";

 
    };
  },
  methods: {
    changeShowHot() {
      this.$emit("changeShowHot", false);
    },
    directTo() {
      this.$emit("changeColor", -1, 140);
      this.$emit("changeShowHot", false);
    },
  },
};
</script>

<style lang="less" scoped>
.hot1 {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: #4ea1c3;
  .hotImg {
    position: fixed;
    left: 50%;
    // top: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    overflow: auto;
    max-width: 450px;
    
    // display: flex;
    // align-items: flex-start;
    // justify-content: center;
    &::-webkit-scrollbar {
      display: none;
    }
    img {
      width: 100%;
      height: auto;
      vertical-align: bottom;
    }
  }
  .closeHot,
  .direct {
    position: absolute;
    width: 10px;
    height: 10px;
    right: 10px;
    top: 10px;
    background-color: transparent;
    cursor: pointer;
  }
}
</style>