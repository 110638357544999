<template>
  <div class="search">
    <!-- 搜索组件 -->
    <!-- 搜索 -->
    <input
      type="text"
      :class="searchList === true ? 'inputed2 inputBorder ' : 'inputed'"
      @focus="searching"
      @blur="leaving"
      @keyup.enter="searchEnter"
      v-model="searchColor"
      ref="search"
    />
    <div
      :class="`mainColorLine colorLine${currentLine}`"
      v-if="showColorLine"
    ></div>
    <!-- 模糊搜索 -->
    <!-- <div class="searchList" v-show="searchList" @mousedown="searchVague($event)"> -->
    <div
      class="searchList"
      v-show="searchList"
      @mousedown="searchVague($event)"
    >
      <ul class="searchListUl">
        <li
          v-for="(item, index) in searchHistory"
          :class="`colorLine colorLine${index + 1}`"
          :key="index"
          @click="fuzzySearch(index)"
        ></li>
      </ul>
      <!-- 外涂/内涂切换 -->
      <!-- <div class="switch" @click="changeFuzzySide" v-show="false"> -->
      <!-- 文字 -->
      <!-- <div class="sideOut side" v-show="side === 'outside'">
          {{
            $store.getters.getLanguageType === "en"
              ? "Exterior"
              : "&nbsp;&nbsp;&nbsp;外涂"
          }}
        </div>
        <div class="sideIn side" v-show="side === 'inside'">
          {{
            $store.getters.getLanguageType === "en"
              ? "Interior"
              : "内涂&nbsp;&nbsp;&nbsp;"
          }}
        </div> -->
      <!-- 开关按钮 -->
      <!-- <div
          class="switchCircle"
          :style="{ left: side === 'outside' ? 'calc(100% - 18px)' : '2px' }"
        ></div> -->
      <!-- </div> -->
    </div>
    <!-- 分割线 -->
    <div class="gapline" v-if="searchList"></div>
    <!-- 清除按钮 -->
    <div
      class="clearSearch"
      @click="clearSearch"
      v-if="searchList"
      @mousedown="searchVague($event)"
    >
      <img src="../assets/options/close.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["outsideColor", "insideColor", "showInsideColor", "showOutsideColor"],
  data() {
    return {
      searchList: false,
      searchHistory: ["红粉色", "蓝紫色","青绿色", "黄橙棕",  "黑白灰"],
      showColorLine: false,
      searchColor: "",
      oldSearchColor: "",
      currentLine: 0,
      isfuzzy: false,
      // 默认的开关
      side: "outside",
    };
  },

  methods: {
    // 清除搜索内容
    clearSearch() {
      this.searchColor = "";
    },
    // 获取表单焦点
    searching() {
      if (this.isfuzzy) {
        this.searchColor = "";
        this.isfuzzy = false;
      }
      this.showColorLine = false;
      this.searchList = true;
    },
    // 按下enter键
    searchEnter() {
      this.$refs.search.blur();
    },
    // 失去表单焦点
    leaving() {
      this.searchList = false;
      // 取消模糊的结果
      if (
        this.searchColor.trim() == "" ||
        !this.searchHistory.includes(this.searchColor)
      ) {
        this.$emit("initColorList");
      }
      this.searchData();
    },
    // 模糊搜索
    searchVague(event) {
      event.preventDefault();
    },
    // 点击模糊的色条
    fuzzySearch(index) {
      this.isfuzzy = true;
      this.searchColor = this.searchHistory[index];
      this.showColorLine = true;
      this.currentLine = index + 1;
      this.$refs.search.blur();
    },
    // 根据内容搜索
    searchData() {
      // console.log(this.searchHistory.includes(this.searchColor));
      // 搜索内容相同且色条相同
      if (
        this.oldSearchColor === this.searchColor &&
        this.searchHistory.includes(this.searchColor) &&
        this.side === this.$store.getters.getCurrentSide
      ) {
        this.$emit("searchSameFuzzy", this.side);
        return;
      }
      // 模糊搜索且色条不同
      if (
        this.searchHistory.includes(this.searchColor) &&
        this.side !== this.$store.getters.getCurrentSide
      ) {
        this.changeFuzzySide();
      }

      // 搜索内容相同
      if (this.oldSearchColor === this.searchColor && !this.isfuzzy) return;
      this.oldSearchColor = this.searchColor;

      if (!this.isfuzzy) {
        // 精确搜索内涂
        for (let key in this.showInsideColor) {
          // 获取精确搜索的列表
          let thatArr = this.getAccurate(
            this.showInsideColor[key]["text-CN"],
            this.showInsideColor[key]["text-EN"]
          );
          // console.log(thatArr)
          if (thatArr.includes(this.searchColor)) {
            this.$emit(
              "searchAccurate",
              this.showInsideColor[key]["id"],
              "inside"
            );
            return;
          }
        }
        // 精确搜索外涂
        for (let key in this.showOutsideColor) {
          // 获取精确搜索的列表
          let thatArr = this.getAccurate(
            this.showOutsideColor[key]["text-CN"],
            this.showOutsideColor[key]["text-EN"]
          );
          if (thatArr.includes(this.searchColor)) {
            this.$emit(
              "searchAccurate",
              this.showOutsideColor[key]["id"],
              "outside"
            );
            return;
          }
        }
      } else {
        let insideSearchList = [];
        let outsideSearchList = [];
        // 判断用户选择的是内涂还是外涂
        if (this.side === "inside") {
          // 获取所有内涂的模糊搜索数据
          for (let key in this.showInsideColor) {
            // 获取模糊搜索的列表
            let thatArr = this.showInsideColor[key]["keyword"];
            if (thatArr.includes(this.searchColor)) {
              insideSearchList.push(this.showInsideColor[key]["id"]);
            }
          }
        } else if (this.side === "outside") {
          // 获取所有外涂的模糊搜索数据
          for (let key in this.showOutsideColor) {
            // 获取模糊搜索的列表
            let thatArr = this.showOutsideColor[key]["keyword"];
            if (thatArr.includes(this.searchColor)) {
              outsideSearchList.push(this.showOutsideColor[key]["id"]);
            }
          }
        }
        this.$emit("searchFuzzy", insideSearchList, outsideSearchList);
      }
    },
    getAccurate(arr1, arr2) {
      let newArray = [];
      newArray.push(arr1[2].split("-", 2)[1]);
      newArray.push(arr1[2]);
      newArray.push(arr1[3]);
      newArray.push(arr2[3]);
      return newArray;
    },
    // 点击切换内外涂
    changeFuzzySide() {
      this.side = this.side === "outside" ? "inside" : "outside";
      this.isfuzzy = true;
      // this.searchColor = "";
      this.$emit("initColorList");
      // this.searchData();
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  position: relative;
  top: 0px;
  margin: 0px 0px;
  opacity: 1;
  z-index: 2;
  .inputed {
    /* background-color: #fff; */
    border-radius: 20px;
    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
    border: 2.5px;
    background-image: url("../assets/options/search.png");
    background-repeat: no-repeat;
    background-position: 96% center;
    background-size: auto 50%;
    height: 20px;
    width: 200px;
    outline: none;
    text-indent: 6px;
    background-color: #f5f6f8;
    font-size: 12px;
    color: grey;
    user-select: text;
  }
  .inputed2 {
    /* background-color: #fff; */
    border-radius: 20px;
    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
    border: 2.5px;
    // background-image: url("../assets/options/close.png");
    background-repeat: no-repeat;
    background-position: 96% center;
    background-size: auto 50%;
    height: 20px;
    width: 200px;
    outline: none;
    text-indent: 6px;
    background-color: #f5f6f8;
    font-size: 12px;
    color: grey;
    user-select: text;
  }
  .mainColorLine {
    position: absolute;
    width: 100%;
    height: 100%;
    // background-image: linear-gradient(to right, #e00707, #ffc1c1);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    pointer-events: none;
  }
  .colorLine1 {
    background-image: linear-gradient(to right, #e00707, #ffc1c1);
  }
  .colorLine2 {
    background-image: linear-gradient(to right,  #ab2fff,#0b9ee0);
  }
    .colorLine3 {
      background-image: linear-gradient(to right, #00fdef, #0fcc06);
    }
  .colorLine4 {
    background-image: linear-gradient(to right, #ffd500, #4f3908);
  }
  .colorLine5 {
    background-image: linear-gradient(to right, #050505, #f6f5f6);
  }
  .clearSearch {
    position: absolute;
    height: 10px;
    right: 4%;
    top: 54%;
    transform: translateY(-50%);
    cursor: pointer;
    img {
      height: 100%;
      width: auto;
      vertical-align: top;
    }
  }
  .inputBorder {
    border-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .gapline {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 92%;
    height: 3px;
    background-color: #ebedf0;
  }
  .searchList {
    width: 100%;
    height: 105px;
    position: absolute;
    top: 100%;
    background-color: #f5f6f8;
    z-index: 1;
    box-sizing: border-box;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
    .searchListUl {
      padding: 10px 8px;
      box-sizing: border-box;
      position: absolute;
      width: 100%;
      .colorLine {
        width: 100%;
        height: 10px;
        border-radius: 10px;
        padding-bottom: 0px;
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      li {
        color: #9c9b9b;
        font-size: 12px;
        padding-bottom: 5px;
        cursor: pointer;
      }
    }
    .switch {
      position: absolute;
      width: 25%;
      height: 20px;

      // border: 1px solid #a0a1a1;
      box-sizing: border-box;
      border-radius: 10px;
      right: 20%;
      top: 50%;
      transform: translate(50%, -50%);
      display: flex;
      align-items: center;
      background: url("../assets/switchBox.png");
      background-size: 100% 100%;
      cursor: pointer;
      .switchCircle {
        position: absolute;
        left: calc(100% - 18px);
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        // background-color: #a0a1a1;
        border-radius: 50%;
        background: url("../assets/switch.png");
        background-size: 100% 100%;
        transition: all 0.5s;
      }
      .side {
        font-size: 12px;
        transform: scale(0.6);
        position: absolute;
      }
      .sideOut {
        left: -8%;
      }
      .sideIn {
        right: -8%;
      }
    }
  }
}
</style>