var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"productions"},[_vm._l((_vm.modelText),function(item,index){return _c('dl',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentProduct == 'Product_' + index),expression:"currentProduct == 'Product_' + index"}],key:index,ref:"productionList",refInFor:true,staticClass:"productionList"},[_vm._l((item.textCN),function(item1,index1){return _c('dd',{directives:[{name:"show",rawName:"v-show",value:(_vm.languageType == 'cn'),expression:"languageType == 'cn'"}],key:index1 + 10},[_vm._v(" "+_vm._s(item1)+" ")])}),_vm._l((item.textEN),function(item1,index1){return _c('dd',{directives:[{name:"show",rawName:"v-show",value:(_vm.languageType == 'en'),expression:"languageType == 'en'"}],key:index1},[_vm._v(" "+_vm._s(item1)+" ")])})],2)}),_c('div',{staticClass:"tabbaroption"},[_c('div',{staticClass:"mask"}),_c('div',{staticClass:"topbox"}),_c('div',{staticClass:"selector"},[_c('div',{staticClass:"slide"},[_c('div',{staticClass:"selected"},[_c('ul',{staticClass:"litseletor"},_vm._l((_vm.brandList),function(brand,index){return _c('li',{key:'brand_' + index,staticClass:"color-selected1",attrs:{"id":'brand_' + index}},[_c('label',{staticClass:"plannings",class:{
                  updata_fontcolor: _vm.brandselector_index === 'brand_' + index,
                },on:{"click":function($event){return _vm.icsClick('brand_' + index)}}},[_vm._v(_vm._s(brand.name))])])}),0)])]),_c('div',{staticClass:"slide"},[_c('div',{staticClass:"selected"},[_c('ul',{staticClass:"litseletor litseletor1"},_vm._l((_vm.outsideColor),function(outside_colors,index){return _c('li',{key:'Product_' + index,staticClass:"color-selected",style:({
                background:
                  _vm.currentProduct == 'Product_' + index
                    ? 'rgb(0 0 0 / 15%)'
                    : '',
              }),attrs:{"id":'Product_' + index},on:{"click":function($event){return _vm.ocsClick('Product_' + index)}}},[_c('img',{staticClass:"ocs-img",class:{
                  updata_fontcolor: _vm.product_index === 'outside_' + index,
                },attrs:{"draggable":"false","src":require(("../assets/productionss/product-" + (index + 1) + ".png")),"alt":""}})])}),0)])])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }