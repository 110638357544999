<template>
  <div
    :class="inAnimation ? 'Anim pointselect' : 'pointselect'"
    @animationend="changeAnime"
    @click="showColorList = false"
  >
    <div class="bigbox">
      <!-- <div class="topbox">
        <div class="inconect" id="SendSim" @click="screenshot">
          <img src="../assets/options/shoucang.png" alt="" />
        </div>
        <div class="search" id="Magnifier" @click="showManifier">
          <img src="../assets/options/fangdajing.png" alt="" />
        </div>
      </div> -->
      <div class="tabbaroption">
        <div class="mask" v-show="dbclickStatus"><!-- glass --></div>
        <!-- 分割线 -->
        <div :class="inAnimation ? 'selectorAnime selector' : 'selector'">
          <!-- 滑动选择行1 -->
          <div class="slide">
            <div class="selected">
              <ul class="litseletor">
                <li
                  v-for="(inside_colors, index) in insideColor"
                  :key="index"
                  :id="index"
                  :class="inAnimation ? 'animeRotateLi' : ''"
                  @dblclick="changeDbStatus('inside', index)"
                  @click.stop="icsClick(index)"
                  :style="{
                    background:
                      currentInsidePoint == index ? 'rgb(0 0 0 / 10%)' : '',
                    opacity:
                      outsidePointDbStatus == 1 && dbIndex != 'point_' + index
                        ? 0.2
                        : 1,
                  }"
                >
                  <div
                    class="color-selected"
                    :style="{
                      background: currentInside['Color'][0],
                    }"
                  >
                    <ul
                      :class="`pointBgc${index + 1} pointBgc`"
                      :ref="`pointBgc${index + 1}`"
                    ></ul>
                    <!-- <img
                      draggable="false"
                      class="ocs-img"
                      :src="
                        require(`../assets/setpoint/Mask_SimPoint_${index}.png`)
                      "
                      alt=""
                    /> -->
                  </div>
                  <!-- 选择撒点颜色 -->
                  <ul
                    :class="`sprinkleColor${index + 1}`"
                    v-show="currentInsidePoint == index && showColorList"
                    @click.stop
                  >
                    <template v-if="index === 0">
                      <li
                        v-for="(item1, index1) in sprinkleColorList1"
                        :key="index1"
                        :class="
                          currentColor1 === item1
                            ? 'sprinkleColorLi activeSprinkleColor'
                            : 'sprinkleColorLi'
                        "
                        :style="{
                          background: item1,
                        }"
                        @click="changeSprinkleColor(index, item1)"
                      ></li>
                    </template>
                    <template v-else-if="index === 1">
                      <li
                        v-for="(item1, index1) in sprinkleColorList2"
                        :key="index1"
                        :class="
                          currentColor2 === item1
                            ? 'sprinkleColorLi activeSprinkleColor'
                            : 'sprinkleColorLi'
                        "
                        :style="{
                          background: item1,
                        }"
                        @click="changeSprinkleColor(index, item1)"
                      ></li>
                    </template>
                  </ul>
                </li>
              </ul>
            </div>
            <!-- inside -->
          </div>
          <!-- 滑动选择行2 -->
          <div class="slide">
            <div class="selected">
              <ul class="litseletor">
                <li
                  v-for="(outside_colors, index) in outsideColor"
                  :key="index"
                  :id="'SimLight_' + index"
                  :class="inAnimation ? 'animeRotateLi' : ''"
                  @click="ocsClick(index)"
                  @dblclick="changeDbStatus('outside', index)"
                  :style="{
                    background:
                      currentOutsidePoint == index ? 'rgb(0 0 0 / 10%)' : '',
                    opacity:
                      insidePointDbStatus == 1 && dbIndex != 'light_' + index
                        ? 0.2
                        : 1,
                  }"
                >
                  <div
                    class="color-selected"
                    :style="{
                      background: currentOutside['Color'][0],
                    }"
                  >
                    <img
                      draggable="false"
                      class="ocs-img"
                      :src="
                        require(`../assets/setpoint/Mask_SimLight_${index}.png`)
                      "
                      alt=""
                    />
                  </div>
                </li>
              </ul>
            </div>
            <!-- outside -->
          </div>
          <!-- 收藏 -->
          <div class="collect" @click="screenshot">
            <img src="../assets/options/shoucang.png" alt="" />
          </div>
          <!-- 放大镜 -->
          <div class="magnifier" @click="showManifier">
            <img src="../assets/options/fangdajing.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Colorselect",
  props: [
    "currentInside",
    "currentOutside",
    "insidePointDbStatus",
    "outsidePointDbStatus",
    "currentInsidePoint",
    "currentOutsidePoint",
    "inAnimation",
    "currentColor1",
    "currentColor2",
  ],
  data() {
    return {
      insidecolorselector_index: -1,
      outsidecolorselector_index: -1,
      insideColor: [
        { id: 0, src: "../assets/setpoint/1.png" },
        { id: 1, src: "../assets/setpoint2.png" },
        { id: 2, src: "../assets/setpoint/3.png" },
      ],
      outsideColor: [
        { id: 0, src: "../assets/setpoint/4.png" },
        { id: 1, src: "../assets/setpoint/5.png" },
        { id: 2, src: "../assets/setpoint/6.png" },
      ],
      sprinkleColorList: [
        "#ffffff",
        "#000000",
        "#8fc31f",
        "#4287CF",
        "#FFC968",
        "#858585",
        "#FF9E94",
      ],
      sprinkleColorList1: this.sprinkleColorList,
      sprinkleColorList2: this.sprinkleColorList,
      insideBgColor: this.currentInside,
      outsideBgColor: this.currentOutside,
      inclicks: -1,
      outclicks: -1,
      dbclickStatus: 0,
      dbIndex: "",
      showColorList: false,
    };
  },

  created() {
    this.$emit("changeTextShow", "outside", true);
    this.$emit("changeTextShow", "inside", true);
    this.deletSprinkleColor();
  },
  mounted() {
    for (let i = 0; i < 3; i++) {
      this.appendPoint(i);
    }
  },
  methods: {
    // 生成拟合点
    appendPoint(index) {
      let Colorarr1 = [
        this.currentColor1 === "" ? "#fff" : this.currentColor1,
        this.currentColor2 === "" ? "#000" : this.currentColor2,
      ];
      let Colorarr2 = [this.currentColor1, this.currentColor2];
      let pointBgcList = [
        this.$refs.pointBgc1[0],
        this.$refs.pointBgc2[0],
        this.$refs.pointBgc3[0],
      ];
      let pointBgc = pointBgcList[index];
      pointBgc.innerHTML = "";
      let pointLength;
      if (index === 2) {
        pointLength = 45;
      } else {
        pointLength = 30;
      }
      for (let j = 0; j < pointLength; j++) {
        let li = document.createElement("li");
        let widthSize = Math.random() * 1.5 + 1;
        let heightSize = Math.random() * 1.5 + 1;
        let positionLeft = Math.random() * 20;
        let positionTop = Math.random() * 20;
        li.style.width = widthSize + "px";
        li.style.height = heightSize + "px";
        li.style.left = positionLeft + "px";
        li.style.top = positionTop + "px";
        if (index === 2) {
          li.style.backgroundColor = Colorarr1[Math.round(Math.random())];
        } else {
          li.style.backgroundColor = Colorarr2[index];
        }
        pointBgc.append(li);
      }
    },
    // 删除可选择元素
    deletSprinkleColor() {
      this.sprinkleColorList1 = this.sprinkleColorList2 = this.sprinkleColorList;
      this.sprinkleColorList1 = this.sprinkleColorList1.filter(
        (item) => item != this.currentColor2
      );
      this.sprinkleColorList2 = this.sprinkleColorList2.filter(
        (item) => item != this.currentColor1
      );
    },
    //
    changeAnime() {
      this.$emit("changeAnime");
    },
    // 双击拟合
    changeDbStatus(type, index) {
      if (
        type === "inside" &&
        ((index === 0 && this.currentColor1 === "") ||
          (index === 1 && this.currentColor2 === ""))
      ) {
        this.showColorList = true;
        return;
      }

      this.showColorList = false;
      this.$emit("changePointDb", type, index);
    },
    // 点击内涂拟合
    icsClick(index) {
      if (this.outsidePointDbStatus) return;
      this.$emit("changePoint", "inside", index);
    },
    // 显示内涂颜色列表
    needshowColorList(bool) {
      this.showColorList = bool;
    },
    // 点击改变内涂拟合颜色
    changeSprinkleColor(index, color) {
      this.needshowColorList(false);
      this.$emit("changeSprinkleColor", index, color);
    },
    //
    ocsClick(index) {
      if (this.insidePointDbStatus) return;
      this.$emit("changePoint", "outside", index);
    },
    showManifier() {
      this.$emit("getManifier", true);
    },
    screenshot() {
      this.$emit("sendImg");
    },
  },
  watch: {
    currentColor1() {
      this.appendPoint(0);
      this.appendPoint(2);
      this.deletSprinkleColor();
    },
    currentColor2() {
      this.appendPoint(1);
      this.appendPoint(2);
      this.deletSprinkleColor();
    },
  },
};
</script>

<style lang="less" scoped>
.pointselect {
  position: absolute;
  bottom: 0;
  /* border:1px solid red; */
  width: 100%;
  height: 184px;
  margin-bottom: 0;
}
.Anim {
  animation: upPointSelect 1s;
}
@keyframes upPointSelect {
  0% {
    bottom: -160px;
  }
  100% {
    bottom: 0px;
  }
}

.bigbox {
  height: 100%;
  width: 100%;
  // align-items: center;
}
.tabbaroption {
  position: relative;

  height: auto;
  width: 100%;
  margin: 0;

  pointer-events: all;
  box-sizing: border-box;
  border: 2px solid #ffffff80;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-image: linear-gradient(#0000000f, #ffffff14);
  // overflow: hidden;
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(255, 255, 255, 0.5);
  background-image: linear-gradient(#0000000f, #ffffff14); */
  background: url(../assets/options/mask.png);
  /* filter: blur(1px); */
  opacity: 0.75;
}
/* .topbox {
  position: relative;
  top: 0;
  width: 100%;
  height: auto;
  justify-content: space-between;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
} */
.inconect {
  position: relative;
  top: 0px;
  /* left: 2%; */
  height: 36px;
  width: 36px;
  pointer-events: all;
  cursor: pointer;
  user-select: none;
  /* border:1px solid red; */
}
.inconect img {
  margin-left: 6px;
  height: 16px;
  width: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.search {
  position: relative;
  top: 0px;
  /* right: 2%; */
  height: 24px;
  width: 24px;
  padding: 6px 6px;
  box-sizing: border-box;
  pointer-events: all;
  cursor: pointer;
  user-select: none;
  /* border:1px solid red; */
}
.search img {
  /* margin-right: 6px; */
  height: 16px;
  width: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.selector {
  position: relative;
  border: none;
  top: 0px;
  width: 100%;
  height: auto;
  margin: 5px 0px 15px;
  user-select: none;
  .collect,
  .magnifier {
    position: absolute;
    width: 18px;
    height: 18px;
    top: 45%;
    transform: translateY(-50%);
    // left: 6%;
    cursor: pointer;
    img {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }
  .collect {
    left: 6%;
  }
  .magnifier {
    right: 6%;
  }
}
.selectorAnime {
  animation: selector 1s ease;
}
@keyframes selector {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.selected {
  padding: 0px;
  width: 88%;
}
.litseletor {
  background-color: transparent;
  list-style-type: none;
  display: flex;
  padding: 0px;
  margin: 0px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  padding: 8.5px 0px;
  // overflow-y: scroll;
}
.litseletor::-webkit-scrollbar {
  display: none;
}
.slide {
  height: 58px;
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}
.litseletor li {
  border-radius: 50%;
  cursor: pointer;
  pointer-events: all;
  position: relative;
}
.animeRotateLi {
  animation: rotateLi 1s;
}
@keyframes rotateLi {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.color-selected {
  margin: 4px;
  width: 20px;
  height: 20px;
  /* border:1px solid blue; */
  border-radius: 50%;
  cursor: pointer;
  /* 判断 */
  /* background:rgb(0 0 0 / 10%); */
  position: relative;
  box-shadow: 2px 2px 8px #00000050;
}
.color-selected .pointBgc {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.color-selected img {
  position: absolute;
  height: 20px;
  width: 20px;
  /* border:1px solid red; */
  pointer-events: none;
  border-radius: 50%;
  pointer-events: none;
}
.color-selected-none {
  pointer-events: none !important;
}

.sprinkleColor1 {
  position: absolute;

  height: 20px;
  padding: 10px 25px 25px;
  bottom: 120%;
  left: -100%;
  border-radius: 30px;
  display: flex;
  // border: 2px solid rgba(255, 255, 255, 0.6);
  background: url("../assets/setpoint/circleBgc.png");
  background-size: 95% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  align-items: center;
  justify-content: center;
  // display: none;
  .sprinkleColorLi {
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 5px;
    margin-right: 18px;
    box-sizing: border-box;
    &:last-child {
      margin-right: 0;
    }
  }
}
.sprinkleColor2 {
  position: absolute;
  height: 20px;
  padding: 10px 25px 25px;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 30px;
  display: flex;
  // border: 2px solid rgba(255, 255, 255, 0.6);
  background: url("../assets/setpoint/circleBgc2.png");
  background-size: 95% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  align-items: center;
  justify-content: center;
  // display: none;
  .sprinkleColorLi {
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 5px;
    margin-right: 18px;
    box-sizing: border-box;
    &:last-child {
      margin-right: 0;
    }
  }
}
.activeSprinkleColor {
  border: 2px solid rgba(100, 100, 100, 0.5);
}
</style>

<style lang="less">
.color-selected .pointBgc li {
  position: absolute;
  border-radius: 50%;
}
</style>