var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"othersop"},[_vm._t("default",function(){return [_c('div',{staticClass:"tabbaroption"},[_c('div',{staticClass:"mask"}),_c('div',{staticClass:"topbox"}),_c('div',{staticClass:"selector1"},[_c('div',{staticClass:"slide1"},[_c('div',{staticClass:"direction",on:{"click":function($event){return _vm.clickArrow('inside', 'left')}}},[_c('img',{ref:"prepng",attrs:{"src":require("../assets/options/pre.png"),"lt":"","id":"prepng"}})]),_c('div',{staticClass:"selected1"},[_c('ul',{ref:"litseletor1",staticClass:"litseletor1"},_vm._l((_vm.classList),function(classify,index){return _c('li',{key:'class_' + index,staticClass:"color-selected1 insideColorLi",style:({
                  background:
                    _vm.classselector_index == 'class_' + classify.id
                      ? 'rgb(0 0 0 / 10%)'
                      : '',
                }),attrs:{"id":'class_' + index},on:{"click":function($event){return _vm.icsClick('class_' + classify.id)}}},[_c('img',{staticClass:"ics-img",attrs:{"draggable":"false","src":classify.src,"alt":""}})])}),0)]),_c('div',{staticClass:"direction",on:{"click":function($event){return _vm.clickArrow('inside', 'right')}}},[_c('img',{ref:"nextpng",attrs:{"src":require("../assets/options/next.png"),"alt":"","id":"nextpng"}})])]),_c('div',{staticClass:"slide"},[_c('div',{staticClass:"direction",on:{"click":function($event){return _vm.clickArrow('outside', 'left')}}},[_c('img',{ref:"prepng2",attrs:{"src":require("../assets/options/pre.png"),"alt":"","id":"prepng2"}})]),_c('div',{staticClass:"selected"},[_c('ul',{ref:"litseletor2",staticClass:"litseletor"},_vm._l((_vm.innovationList),function(innovation,index){return _c('li',{key:index,staticClass:"outsideColorLi",style:({
                  background:
                    _vm.innovationselector_index == innovation.id
                      ? 'rgb(0 0 0 / 10%)'
                      : '',
                }),attrs:{"id":innovation.id},on:{"click":function($event){return _vm.ocsClick(index)}}},[(!innovation.img)?_c('div',{staticClass:"color-selected",style:({
                    background: innovation.dfColor,
                  })},[_c('img',{staticClass:"ocs-img",attrs:{"draggable":"false","src":require("../assets/options/button_mask.png"),"alt":""}})]):_c('div',{staticClass:"color-selected"},[_c('img',{style:({
                      height:
                        _vm.classselector_index == 'class_1' ? '75px' : '60px',
                    }),attrs:{"draggable":"false","src":innovation.img,"alt":""}})])])}),0)]),_c('div',{staticClass:"direction",on:{"click":function($event){return _vm.clickArrow('outside', 'right')}}},[_c('img',{ref:"nextpng2",attrs:{"src":require("../assets/options/next.png"),"alt":"","id":"nextpng2"}})])])])])]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }