<template>
  <div class="Version">
    <div class="Version-container">
      <!-- 提示图片 -->
      <div class="tipImg">
        <img src="../assets/tip.png" alt="" />
      </div>
      <!-- 文本内容 -->
      <dl class="article">
        <dt style="color:#005bac">
          {{ articleC.title }} <span>{{ articleC.tip }} </span>
        </dt>
        <dd  style="color:#005bac" v-for="(item, index) in articleC.p" :key="index" v-html="item">
          
        </dd>
      </dl>
      <dl class="article">
        <dt>
          {{ articleV.title }} <span>{{ articleV.tip }}</span>
        </dt>
        <dd v-for="(item, index) in articleV.p" :key="index">
          {{ item }}
        </dd>
      </dl>
      <!-- 关闭按钮 -->
      <div class="close" @click="close">
        <span v-if="OnceCountDown">Close after ({{ Countdown }}) seconds </span>
        <span
          class="closeBtn"
          :style="{ letterSpacing: languageType === 'en' ? '' : '0.2em' }"
          >{{ languageType === "en" ? "Close" : "关闭" }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["ShowVersion", "Countdown", "OnceCountDown", "languageType"],
  data() {
    return {
      // 颜色标准文本

      articleColor: {
        en: {
          title: "Color standard",
          tip: "",
          p: [
            "<h4>Calibration conditions:</h4> D65 Artificial Daylight; Iphone 12 display with “Ture Tone” function; Brightness 85-90%",
            "Illumination: 3000-3600 Lux",
            "<h4>Use suggestions:</h4> Operate in areas with diffuse reflection of sunlight (avoid direct sunlight), sensitive colors: grey, yellowish green and other light colors.",
          ],
        },
        cn: {
          title: "调色标准",
          tip: "",
          p: [
            "<h4>校准条件：</h4>D65标准光源箱，iphone12默认开启原彩显示，亮度85-90%",
            "照度：3000-3600 LUX",
            "<h4>使用建议：</h4>打开窗户在日光漫反射区域做颜色对比；尤其灰色，黄绿以及浅色系等显色受光线影响较大。",
          ],
        },
      },
      articleC: "",
      articleVersion: {
        en: {
          title: "Version update content ",
            tip: "Version 3.2 ",
          p: [
            "· Upgrade 195 more interior colors; ",
            "· Upgrade 224 more external colors; ",
            "· Upgrade color gradientsorting; ",
            "· Upgrade contact pop-up window；",
            "· Upgrade popular colorentrance;",
          ],
        },
        cn: {
          title: "版本更新内容",
        tip: "Version 3.2 ",
          p: [
            "· 新增内涂颜色数据195个;",
            "· 新增外涂颜色数据224个;",
            "· 新增颜色梯度排序;",
            "· 新增联系我们弹窗;",
            "· 新增流行色入口;",
          ],
        },
      },
      articleV: "",
    };
  },
  created() {
    this.articleC = this.articleColor.en;
    this.articleV = this.articleVersion.en;
  },
  methods: {
    close() {
      this.$emit("closeVersion");
    },
  },
  watch: {
    languageType(newVal, oldVal) {
      if (newVal === "en") {
        this.articleC = this.articleColor.en;
        this.articleV = this.articleVersion.en;
      } else {
        this.articleC = this.articleColor.cn;
        this.articleV = this.articleVersion.cn;
      }
    },
  },
};
</script>
<style lang="less">
.Version{
  dd{
    h4{
      display: inline;
      font-size: 14px;
    }
  }
}
</style>
<style lang="less" scoped>
     
.Version {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  .Version-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 450px;
    background-color: #f4f4f4;
    padding: 15px 0 15px 55px;
    box-sizing: border-box;
    box-shadow: -3px 3px 10px rgba(0, 0, 0, 0.3);
    .tipImg {
      position: absolute;
      height: 22px;
      transform: translateX(-160%);
      img {
        height: 100%;
        width: auto;
      }
    }

    .article {
      width: 100%;
      border-bottom: 2px solid #bfbfbf;
      padding-bottom: 10px;
      background-color: #f4f4f4;
      margin-bottom: 8px;
  
      dt {
        color: #595757;
        font-weight: bold;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: 10px;
        span {
          font-size: 12px;
          padding-right: 20px;
          color: #595757;
          font-weight: normal;
        }
      }
      dd {
        font-size: 12px;
        line-height: 18px;
        color: #595757;
        padding-right: 20px;
        margin-bottom: 2px;
      }
         
    }

    .close {
      color: #595757;
      font-size: 12px;
      padding-right: 20px;
      float: right;
      margin-top: 5px;
      .closeBtn {
        display: inline-block;
        padding: 5px 10px;
        background-color: #bfbfbf;
        color: #fff;
        border-radius: 5px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}
</style>