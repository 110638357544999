<template>
  <div class="home">
    <V3DApp />
  </div>
</template>
<script>
// @ is an alias to /src
import V3DApp from "../components/V3DApp.vue";

export default {
  name: "App",
  components: {
    V3DApp,
  },
  mounted: function () {},
  created() {
    //初始化数据
    let insideId = this.getUrlKey("inside") ? this.getUrlKey("inside") : 0;
    let outsideId = this.getUrlKey("outside") ? this.getUrlKey("outside") : 366;
    let point = this.getUrlKey("SimPoint") ? this.getUrlKey("SimPoint") : -1;
    let light = this.getUrlKey("SimLight") ? this.getUrlKey("SimLight") : -1;
    let product = this.getUrlKey("product")
      ? this.getUrlKey("product")
      : "Product_0";
    let scene = this.getUrlKey("scene") ? this.getUrlKey("scene") : "Scene_0";
    let guide = this.getUrlKey("guide") ? this.getUrlKey("guide") : 0;
    let dbclick = this.getUrlKey("dbclick") ? this.getUrlKey("dbclick") : 0;
    this.$store.commit("setInsideId", insideId);
    this.$store.commit("setOutsideId", outsideId);
    this.$store.commit("setPoint", point);
    this.$store.commit("setLight", light);
    this.$store.commit("setProduct", product);
    this.$store.commit("setScene", scene);

    //初始化 URL
    this.$router.push({
      path: "/",
      query: {
        inside: insideId,
        outside: outsideId,
        SimPoint: point,
        SimLight: light,
        product: product,
        scene: scene,
        guide,
        dbclick,
      },
    });
  },
};
</script>
<style >
.tabbaroption hr {
  margin: 0px;
  width: calc(100% - 42px);
  height: 1px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  box-sizing: border-box;
  border: none;
}
.topbox {
  position: relative;
  top: 0;
  height: auto;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
}

.search {
  position: relative;
  top: 0px;
  margin: 6px 0px;
  opacity: 1;
  z-index: 2;
}
.inputed {
  /* background-color: #fff; */
  border-radius: 20px;
  border: 2.5px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAMAAADW3miqAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAFKUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAANWc2JIAAABtdFJOUwAc+uCw/uj9Ww/D1QZZXfJ7pZBK5q6nrL/AGdSo+bj2TGrQM42XyTgoNfzbB8pFxCYK7+f7loAnsvDFgi2FqTLf2iHjcuLGIARwrfQDQqK2qjq3Nt0SPMyTz8ERASuSCTCayJkuPU2RpNOzzQwQTMMIAAABTElEQVQ4y82U1XPCQBCHjwAJLi1QKO5eoO7u7u5uv///tQnM0LvLdegj+3Tf5ptsbmc3hHR5pGdCvujwUHB0+k9l4AztCB0IlW+v9tDoH8mO+7TTeUXvGD4AKXvTgqNdM3Bp5R2rHfgq/HKxDAQdnPQOfDboxFUOyLDOK1BtsKmTY5jTdOL6AtE6/wWnwB7NeeBNfxcTsEZhBqgL+gZsUDiJBUHn1rfQR+EEAqL+riJMEdAjkkxY+o+0QpHMFG9HFSmKbJgXOI44IhROAc96KQEkKZwDHvRSDKV+wtQr3fNOLzDLJF4AOzc+BhlSgU09ATXGMqgD5uHeffcIlPNULVmdYJduNGuAeX9nu3n3RKy5DYqFtyq36lzDbfObcnH1IHlcisAixcP2RimRMUIsQossLm+GjcZUINnqj2Y5O260ark7771z0Nutv6Qfm0RJlZP5AFEAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: 96% center;
  background-size: auto 50%;
  height: 20px;
  width: 200px;
  outline: none;
  text-indent: 6px;
  background-color: #f5f6f8;
  font-size: 12px;
  color: grey;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
</style>