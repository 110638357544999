/* __V3D_TEMPLATE__ - template-based file; delete this line to prevent this file from being updated */

/* eslint-disable */

import Vuex from 'vuex'
import store from '../store/index.js'


var CONTAINER_ID = 'container';

/**
 * Path to prepend to request URLs for the scene .gltf file and the visual logic
 * .js file.
 */
var REL_URL_PREFIX = '../';
var REL_URL_PREFIX2 = 'model/Product';
var ProgressID = 'simple-preloader-width';
var initOptions;

/**
 * Load the visual logic .js and/or .xml file or not. The Puzzles Editor is
 * currently not fully supported.
 * See: https://www.soft8soft.com/docs/manual/en/programmers_guide/Integration-with-Reactjs-Vuejs.html#using_the_puzzles_editor
 */
var LOAD_LOGIC_FILES = true;

function createApp(productName = 'Product_1', inside = '', outside = '', point = '', light = '') {

    var params = v3d.AppUtils.getPageParams();
    var PUZZLES_DIR = '/puzzles/';
    var logicURL = params.logic ? params.logic : '__LOGIC__visual_logic.js'.replace('__LOGIC__', REL_URL_PREFIX);
    var sceneURL = params.load ? params.load : '__URL__model.gltf.xz'.replace('__URL__', REL_URL_PREFIX2 + '/' + productName + '/');

    if (!sceneURL) {
        console.log('No scene URL specified');
        return;
    }
    // some puzzles can benefit from cache
    v3d.Cache.enabled = true;

    return new Promise(function (resolve) {

        if (LOAD_LOGIC_FILES) {
            if (v3d.AppUtils.isXML(logicURL)) {
                var logicURLJS = logicURL.match(/(.*)\.xml$/)[1] + '.js';
                new v3d.PuzzlesLoader().loadEditorWithLogic(PUZZLES_DIR, logicURLJS,
                    function () {
                        var initOptions = v3d.PL ? v3d.PL.execInitPuzzles({
                            container: CONTAINER_ID
                        }).initOptions : {
                                useFullscreen: true
                            };
                        var appInstance = loadScene(sceneURL, initOptions);
                        v3d.PE.viewportUseAppInstance(appInstance);
                        resolve(appInstance);
                    }
                );
            } else if (v3d.AppUtils.isJS(logicURL)) {
                new v3d.PuzzlesLoader().loadLogic(logicURL, function () {

                    initOptions = v3d.PL ? v3d.PL.execInitPuzzles({
                        container: CONTAINER_ID
                    }).initOptions : {
                            useFullscreen: true
                        };
                    resolve(loadScene(sceneURL, initOptions, inside, outside, point, light, productName));
                });
            } else {
                resolve(loadScene(sceneURL, {
                    useFullscreen: true
                }));
            }
        } else {
            resolve(loadScene(sceneURL, {
                useFullscreen: true
            }));
        }

    }).catch(function (err) {
        console.error(err);
    });
}

function loadScene(sceneURL, initOptions, inside = '', outside = '', point = '', light = '', productName = '') {

    initOptions = initOptions || {};

    var ctxSettings = {};
    if (initOptions.useBkgTransp) ctxSettings.alpha = true;
    if (initOptions.preserveDrawBuf) ctxSettings.preserveDrawingBuffer = true;

    var preloader = initOptions.useCustomPreloader ?
        createCustomPreloader(initOptions.preloaderProgressCb,
            initOptions.preloaderEndCb) :
        new v3d.SimplePreloader({
            container: CONTAINER_ID
        });

    if (v3d.PE) {
        puzzlesEditorPreparePreloader(preloader);
    }

    var app = new v3d.App(CONTAINER_ID, ctxSettings, preloader);
    if (initOptions.useBkgTransp) {
        app.clearBkgOnLoad = true;
        app.renderer.setClearColor(0x000000, 0);
    }

    // namespace for communicating with code generated by Puzzles
    app.ExternalInterface = {};
    prepareExternalInterface(app);

    if (initOptions.preloaderStartCb) initOptions.preloaderStartCb();
    if (initOptions.useFullscreen) {
        initFullScreen();
    } else {
        var fsButton = document.getElementById('fullscreen_button');
        if (fsButton) fsButton.style.display = 'none';
    }

    sceneURL = initOptions.useCompAssets ? sceneURL + '.xz' : sceneURL;
    app.loadScene(sceneURL, function () {
        app.enableControls();
        app.run();

        if (v3d.PE) v3d.PE.updateAppInstance(app);
        if (v3d.PL) v3d.PL.init(app, initOptions);


        if (inside) {
            if (point) {
                SetColor(inside, 'inside', 'click', '', productName, function () {
                    if (point.indexOf("-1") > 0) return
                    if (point.indexOf("point") >= 0) {
                        setPoint(inside, point)
                    } else {
                        setLight(inside, point)
                    }
                })
            } else {

                SetColor(inside, 'inside', 'click', '', productName)
            }

        }
        if (outside) {
            if (light) {
                SetColor(outside, 'outside', 'click', '', productName, function () {
                    if (light.indexOf("-1") > 0) return
                    if (light.indexOf("point") >= 0) {
                        setPoint(outside, light)
                    } else {
                        setLight(outside, light)
                    }
                })
            } else {
                SetColor(outside, 'outside', 'click', '', productName)
            }
        }
        LoadProduct
        runCode(app);
    }, null, function () {
        console.log('Can\'t load the scene ' + sceneURL);
    });

    return app;
}

function createCustomPreloader(updateCb, finishCb) {
    function CustomPreloader() {
        v3d.Preloader.call(this);
    }

    CustomPreloader.prototype = Object.assign(Object.create(v3d.Preloader.prototype), {
        onUpdate: function (percentage) {
            v3d.Preloader.prototype.onUpdate.call(this, percentage);
            if (updateCb) updateCb(percentage);
        },
        onFinish: function () {
            v3d.Preloader.prototype.onFinish.call(this);
            if (finishCb) finishCb();
        }
    });

    return new CustomPreloader();
}

/**
 * Modify the app's preloader to track the loading process in the Puzzles Editor.
 */
function puzzlesEditorPreparePreloader(preloader) {
    // backward compatibility for loading new projects within the old Puzzles Editor
    if (v3d.PE.loadingUpdateCb !== undefined && v3d.PE.loadingFinishCb !== undefined) {
        var _onUpdate = preloader.onUpdate.bind(preloader);
        preloader.onUpdate = function (percentage) {
            _onUpdate(percentage);
            v3d.PE.loadingUpdateCb(percentage);
        }

        var _onFinish = preloader.onFinish.bind(preloader);
        preloader.onFinish = function () {
            _onFinish();
            v3d.PE.loadingFinishCb();
        }
    }
}

function initFullScreen() {

    var fsButton = document.getElementById('fullscreen_button');
    if (!fsButton) return;

    var container = document.getElementById(CONTAINER_ID);

    if (document.fullscreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.msFullscreenEnabled)
        fsButton.style.display = 'inline';

    fsButton.addEventListener('click', function (event) {
        event.stopPropagation();
        if (document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement) {
            exitFullscreen();
        } else
            requestFullscreen(container);
    });

    function changeFullscreen() {
        if (document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement) {
            fsButton.classList.remove('fullscreen-open');
            fsButton.classList.add('fullscreen-close');
        } else {
            fsButton.classList.remove('fullscreen-close');
            fsButton.classList.add('fullscreen-open');
        }
    }

    document.addEventListener('webkitfullscreenchange', changeFullscreen);
    document.addEventListener('mozfullscreenchange', changeFullscreen);
    document.addEventListener('msfullscreenchange', changeFullscreen);
    document.addEventListener('fullscreenchange', changeFullscreen);

    function requestFullscreen(elem) {
        if (elem.requestFullscreen)
            elem.requestFullscreen();
        else if (elem.mozRequestFullScreen)
            elem.mozRequestFullScreen();
        else if (elem.webkitRequestFullscreen)
            elem.webkitRequestFullscreen();
        else if (elem.msRequestFullscreen)
            elem.msRequestFullscreen();
    }

    function exitFullscreen() {
        if (document.exitFullscreen)
            document.exitFullscreen();
        else if (document.mozCancelFullScreen)
            document.mozCancelFullScreen();
        else if (document.webkitExitFullscreen)
            document.webkitExitFullscreen();
        else if (document.msExitFullscreen)
            document.msExitFullscreen();
    }
}

function prepareExternalInterface(app) {
    // register functions in the app.ExternalInterface to call them from Puzzles, e.g:
    // app.ExternalInterface.myJSFunction = function() {

    // }

}

function runCode(app) {
    // add your code here, e.g. 
    // app.showFPS();
    if (v3d._customEvents) {
        v3d._customEvents.dispatchEvent({
            type: "onload"
        })
    }
}


function showLoading() {
    var loadView = document.getElementById('simple-preloader-background');

    loadView.style.display = 'block';
}

function hideLoading() {
    var loadView = document.getElementById('simple-preloader-background');
    loadView.style.display = 'none';
}



let loadingNum = 0;

function showMiniLoading() {
    var loadView = document.getElementById('simple-preloader-background');
   
    if (loadingNum < 2) {
        loadView.style.backgroundColor = "#fff"
    } else if (loadingNum >= 2) {
        loadView.style.pointerEvents = "all"
        loadView.style.animation = "none"
        loadView.style.backgroundColor = "transparent"
        loadView.style.opacity = "1"
    }
    loadView.style.display = 'block';
}

function hideMiniLoading() {
    var loadView = document.getElementById('simple-preloader-background');
    loadView.style.backgroundColor = "#fff"
    loadingNum++
    if (loadingNum == 2) {
        loadView.style.opacity = "0"
        loadView.style.pointerEvents = "none"
        TweenCamera("start-camera", "start-target", 0.6)
    } else if (loadingNum > 2) {
        loadView.style.display = 'none'
    }
}



function TweenCamera(start = "start-camera", target = "start-target", time = 0.2, needTackScreen) {
    // var img = 
    v3d.puzzles.procedures.TweenCamera(start, target, time, needTackScreen);

    // return img
}

function TakeScreen() {
    return v3d.puzzles.procedures.TakeScreen()
}


function SetSceenScale(SceenScale) {
    v3d.puzzles.procedures.SetSceenScale(SceenScale);
}


function SetColor(param, ObjectName, click = 'click', iconName = '', productName = '', callback) {
    // 开启渲染
    // v3d.puzzles.procedures.enableRender()
    var materialUrl = 'model/Material/' + param.Material.split('_')[0] + '/' + param.Material + '.gltf.xz'

    if (click == 'click') {
        if (ObjectName == 'inside') {
            if (param.Material == store.getters.getInsideMaterial) {
                var sizeKey = [];
                var valueKey = [];
                var switchKey = [];
                if (click == 'click') {
                    if (param.Size != undefined) {
                        param.Size.forEach((item, index) => {
                            sizeKey.push('Size_' + index)
                        });
                        v3d.puzzles.procedures.SetValue(sizeKey, param.Size, param.Material)
                    }
                    if (param.Value != undefined) {
                        param.Value.forEach((item, index) => {
                            valueKey.push('Value_' + index)
                        })
                        v3d.puzzles.procedures.SetValue(valueKey, param.Value, param.Material)
                    }
                    if (param.Switch != undefined) {
                        param.Switch.forEach((item, index) => {
                            switchKey.push('Switch_' + index)
                        })
                        v3d.puzzles.procedures.SetValue(switchKey, param.Switch, param.Material)
                    }
                }
                if (param.Light != undefined) {
                    v3d.puzzles.procedures.SetLightIntensity(param.Light, 'Light')
                }
                v3d.puzzles.procedures.SetColor(param.Color, param.Material);

                if (productName != '') {
                    var aourl = 'model/Product/' + productName + '/' + 'Product_AO.png'
                    v3d.puzzles.procedures.ReplaceTexture(aourl, param.Material, 'AO');
                }
                if (iconName == 'innovation') {
                    v3d.puzzles.procedures.enableRender()
                    v3d.puzzles.procedures.ReplaceTexture(param.Texture, param.Material);
                }
                // 停止渲染
                if (iconName != "innovation") {
                    v3d.puzzles.procedures.enableRender()
                    v3d.puzzles.procedures.disableRender()
                }
                return
            }
        }

        if (ObjectName == 'outside') {
            if (param.Material == store.getters.getOutSideMaterial) {
                var sizeKey = [];
                var valueKey = [];
                var switchKey = [];
                if (click == 'click') {
                    if (param.Size != undefined) {
                        param.Size.forEach((item, index) => {
                            sizeKey.push('Size_' + index)
                        });
                        v3d.puzzles.procedures.SetValue(sizeKey, param.Size, param.Material)
                    }
                    if (param.Value != undefined) {
                        param.Value.forEach((item, index) => {
                            valueKey.push('Value_' + index)
                        })
                        v3d.puzzles.procedures.SetValue(valueKey, param.Value, param.Material)
                    }
                    if (param.Switch != undefined) {
                        param.Switch.forEach((item, index) => {
                            switchKey.push('Switch_' + index)
                        })
                        v3d.puzzles.procedures.SetValue(switchKey, param.Switch, param.Material)
                    }
                }
                if (param.Light != undefined) {
                    v3d.puzzles.procedures.SetLightIntensity(param.Light, 'Light')
                }
                v3d.puzzles.procedures.SetColor(param.Color, param.Material);

                if (productName != '') {
                    var aourl = 'model/Product/' + productName + '/' + 'Product_AO.png'

                    v3d.puzzles.procedures.ReplaceTexture(aourl, param.Material, 'AO');
                }

                if (iconName == 'innovation') {
                    v3d.puzzles.procedures.enableRender()
                    v3d.puzzles.procedures.ReplaceTexture(param.Texture, param.Material);
                }
                // 停止渲染
                if (iconName != "innovation") {
                    v3d.puzzles.procedures.enableRender()
                    v3d.puzzles.procedures.disableRender()
                }
                return
            }
        }
    }

    showMiniLoading()
    v3d.puzzles.procedures.AppendScene(materialUrl, function () {

        if (click == 'dbclick') {
            v3d.puzzles.procedures.AssignMaterial(param.Material, 'inside');
            v3d.puzzles.procedures.AssignMaterial(param.Material, 'outside');
            store.commit('setInsideMaterial', param.Material)
            store.commit('setOutsideMaterial', param.Material)
        } else {
            if (ObjectName == 'inside') {
                store.commit('setInsideMaterial', param.Material)

            }
            if (ObjectName == 'outside') {
                store.commit('setOutsideMaterial', param.Material)
            }
            v3d.puzzles.procedures.AssignMaterial(param.Material, ObjectName);
        }
        var sizeKey = [];
        var valueKey = [];
        var switchKey = [];

        if (iconName != 'icon-1') {
            if (param.Size != undefined) {
                param.Size.forEach((item, index) => {
                    sizeKey.push('Size_' + index)
                });
                v3d.puzzles.procedures.SetValue(sizeKey, param.Size, param.Material)
            }
            if (param.Value != undefined) {
                param.Value.forEach((item, index) => {
                    valueKey.push('Value_' + index)
                })
                v3d.puzzles.procedures.SetValue(valueKey, param.Value, param.Material)
            }
            if (param.Switch != undefined) {
                param.Switch.forEach((item, index) => {
                    switchKey.push('Switch_' + index)
                })
                v3d.puzzles.procedures.SetValue(switchKey, param.Switch, param.Material)
            }
        }
        if (param.Light != undefined) {
            v3d.puzzles.procedures.SetLightIntensity(param.Light, 'Light')
        }
        v3d.puzzles.procedures.SetColor(param.Color, param.Material);

        if (productName != '') {
            var aourl = 'model/Product/' + productName + '/' + 'Product_AO.png'
            v3d.puzzles.procedures.ReplaceTexture(aourl, param.Material, 'AO');
        }
        if (iconName == 'innovation') {
            v3d.puzzles.procedures.enableRender()
            v3d.puzzles.procedures.ReplaceTexture(param.Texture, param.Material);
        }
        hideMiniLoading()
        v3d.puzzles.procedures.UnloadScene(materialUrl);
        if (callback) {
            callback()
        }
        // 停止渲染
        if (iconName != "innovation") {
            v3d.puzzles.procedures.enableRender()
            v3d.puzzles.procedures.disableRender()
        }
    }, ProgressID);
}


function setColorAndMaterial(param, ObjectName, click = 'click', iconName = '', productName = '', callback) {

    var materialUrl = 'model/Material/' + param.Material.split('_')[0] + '/' + param.Material + '.gltf.xz'
    v3d.puzzles.procedures.AppendScene(materialUrl, function () {

        if (click == 'dbclick') {
            v3d.puzzles.procedures.AssignMaterial(param.Material, 'inside');
            v3d.puzzles.procedures.AssignMaterial(param.Material, 'outside');
            store.commit('setInsideMaterial', param.Material)
            store.commit('setOutsideMaterial', param.Material)
        } else {
            if (ObjectName == 'inside') {
                store.commit('setInsideMaterial', param.Material)
            }
            if (ObjectName == 'outside') {
                store.commit('setOutsideMaterial', param.Material)
            }

            v3d.puzzles.procedures.AssignMaterial(param.Material, ObjectName);
        }
        var sizeKey = [];
        var valueKey = [];
        var switchKey = [];
        if (iconName != 'icon-1') {
            if (param.Size != undefined) {
                param.Size.forEach((item, index) => {
                    sizeKey.push('Size_' + index)
                });
                v3d.puzzles.procedures.SetValue(sizeKey, param.Size, param.Material)
            }
            if (param.Value != undefined) {
                param.Value.forEach((item, index) => {
                    valueKey.push('Value_' + index)
                })
                v3d.puzzles.procedures.SetValue(valueKey, param.Value, param.Material)
            }
            if (param.Switch != undefined) {
                param.Switch.forEach((item, index) => {
                    switchKey.push('Switch_' + index)
                })
                v3d.puzzles.procedures.SetValue(switchKey, param.Switch, param.Material)
            }
        }
        if (param.Light != undefined) {
            v3d.puzzles.procedures.SetLightIntensity(param.Light, 'Light')
        }

        if (productName != '') {
            var aourl = 'model/Product/' + productName + '/' + 'Product_AO.png'
            v3d.puzzles.procedures.ReplaceTexture(aourl, param.Material, 'AO');
        }
        if (iconName == 'innovation') {
            v3d.puzzles.procedures.ReplaceTexture(param.Texture, param.Material);
        }
        v3d.puzzles.procedures.SetColor(param.Color, param.Material, click, '', productName, function () {
            if (callback) callback()
        });
        v3d.puzzles.procedures.UnloadScene(materialUrl);
    }, ProgressID);
}

function setPoint(param, type, click = 'click', productName = '', color1 = "#fff", color2 = "#000") {
    // 开启渲染
    if (click == 'dbclick') {
        SetColor(param, 'outside', click, 'icon-1', productName, function () {
            setPoint(
                param,
                type,
                "click",
                "",
                color1,
                color2
            );
        })
    }
    var keyList = [];
    var valueList = [];
    switch (type) {
        case 'point_0':
            keyList = ['Size_2', 'Switch_0', 'Switch_1'];
            valueList = ['29', '1', '0']
            break;
        case 'point_1':
            keyList = ['Size_2', 'Switch_0', 'Switch_1'];
            valueList = ['26', '0', '1']
            break;
        case 'point_2':
            keyList = ['Size_2', 'Switch_0', 'Switch_1'];
            valueList = ['22', '1', '1']
            break;
        default:
            param.Switch.forEach((item, index) => {
                keyList.push('Switch_' + index)
                valueList.push(item)
            })
            break;
    }
    v3d.puzzles.procedures.SetValue(keyList, valueList, param.Material);
    v3d.puzzles.procedures.setMaterialColor(param.Material, "Color_4", 0, 0, 0, color1)
    v3d.puzzles.procedures.setMaterialColor(param.Material, "Color_5", 0, 0, 0, color2)

    // 停止渲染
    if (click != 'dbclick') {
        v3d.puzzles.procedures.enableRender()
        v3d.puzzles.procedures.disableRender()
    }
}

function setLight(param, type, click = 'click') {
    // 开启渲染
    // v3d.puzzles.procedures.enableRender()
    if (click == 'dbclick') {
        SetColor(param, 'inside', click, 'icon-1')
    }
    var keyList = [];
    var valueList = [];
    switch (type) {
        case 'light_0':
            keyList = ['Value_1', 'Value_2', 'Value_3', 'Switch_2'];
            valueList = ['0.5', '0.6', '0.5', '0']
            break;
        case 'light_1':
            keyList = ['Value_1', 'Value_2', 'Value_3', 'Size_0', 'Size_1', 'Switch_2'];
            valueList = ['0.3', '0.05', '0.2', '60', '0.265', '2']
            break;
        case 'light_2':
            keyList = ['Value_1', 'Value_2', 'Value_3', 'Size_0', 'Size_1', 'Switch_2'];
            valueList = ['0.3', '0.05', '0.2', '35', '0.24', '2']
            break;
        default:
            keyList = ['Value_1', 'Value_2', 'Value_3', 'Switch_2'];
            valueList = ['0.1', '0.2', '0', '0']
            break;
    }
    v3d.puzzles.procedures.SetValue(keyList, valueList, param.Material);
    // 停止渲染
    if (click != 'dbclick') {
        v3d.puzzles.procedures.enableRender()
        v3d.puzzles.procedures.disableRender()
    }
}

function LoadProduct(productName, insideColor, outsideColor, pointNmae = '', lightName = '', callback) {


    var url = 'model/Product/__URL__/model.gltf.xz'.replace('__URL__', productName)

    showMiniLoading()
    v3d.puzzles.procedures.LoadScene(url, productName, function () {

        setColorAndMaterial(insideColor, 'inside', 'click', '', productName, callback)
        setColorAndMaterial(outsideColor, 'outside', 'click', '', productName, callback)
        if (pointNmae != '') {
            setPoint(insideColor, pointNmae)
        }
        if (lightName != '') {
            setLight(outsideColor, lightName)
        }

        TweenCamera("start-camera", "start-target", 0.6)
        hideMiniLoading()
        v3d.puzzles.procedures.enableRender()
        v3d.puzzles.procedures.disableRender()
    }, ProgressID)
}

function updateScene(sceneName, producrList = []) {
    showMiniLoading()
    var url = 'model/Scene/__URL__/model.gltf.xz'.replace('__URL__', sceneName)
    v3d.puzzles.procedures.LoadScene(url, sceneName, function () {
        if (producrList.length > 0) {
            producrList.forEach((item, index) => {
                loadProductToScene(item.product, item.position, item.inside, item.outside)
            })
        }
        hideMiniLoading()
        v3d.puzzles.procedures.changeCamera()
        // 
        v3d.puzzles.procedures.enableRender()
        v3d.puzzles.procedures.disableRender()
    }, ProgressID)
}

//  , , , ,双击状态,拟合内层,拟合外层
function loadProductToScene(productName, positionName, insideColor, outsideColor, clickStatus, SimPoint, SimLight, color1, color2) {
    // 开启渲染
    v3d.puzzles.procedures.enableRender()
    var productUrl = 'model/Product/__URL__.gltf.xz'.replace('__URL__', productName + '/model_' + positionName)

    showMiniLoading()
    v3d.puzzles.procedures.AppendScene(productUrl, function () {
        v3d.puzzles.procedures.SnapObject('model_' + positionName, positionName)

        setColorByPosition(insideColor, 'inside', positionName, '', clickStatus, SimPoint, SimLight, color1, color2)
        setColorByPosition(outsideColor, 'outside', positionName, '', clickStatus, SimPoint, SimLight, color1, color2)
        hideMiniLoading()
    }, ProgressID)
}

function unLoadProductFromScene(productName, positionName) {
    var productUrl = 'model/Product/__URL__.gltf.xz'.replace('__URL__', productName + '/model_' + positionName)

    v3d.puzzles.procedures.UnloadScene('model_' + positionName);
    v3d.puzzles.procedures.enableRender()
    v3d.puzzles.procedures.disableRender()
}


function setColorByPosition(param, ObjectName, positionName, productName, clickStatus, SimPoint, SimLight, color1, color2) {

    var materialUrl = 'model/Material/' + param.Material.split('_')[0] + '/' + param.Material + '.gltf.xz'
    v3d.puzzles.procedures.AppendScene(materialUrl, function () {
        if (productName != '') {
            var aourl = 'model/Product/' + productName + '/' + 'Product_AO.png'
            v3d.puzzles.procedures.ReplaceTexture(aourl, param.Material, 'AO');
        }
        v3d.puzzles.procedures.AssignMaterial(param.Material, ObjectName + '_' + positionName);

        v3d.puzzles.procedures.SetColor(param.Color, param.Material);
        if (SimPoint !== undefined) {
            if (ObjectName === 'inside' && SimPoint.split("_").length > 1) {
                if (clickStatus === "outside") {
                    setLight(param, "light_" + SimLight.split("_")[1])
                } else {

                    setPoint(param, "point_" + SimPoint.split("_")[1], "click", "", color1, color2)
                }
            } else if (ObjectName === "outside" && SimLight.split("_").length > 1) {
                if (clickStatus === "inside") {
                    setPoint(param, "point_" + SimPoint.split("_")[1], "click", "", color1, color2)
                } else {
                    setLight(param, "light_" + SimLight.split("_")[1])
                }
            } else {
                if (SimPoint.split("_").length <= 1) {
                    setPoint(param, "")
                } else if (SimLight.split("_").length <= 1) {
                    setLight(param, "")
                }
            }
        }


        v3d.puzzles.procedures.UnloadScene(materialUrl);
    }, ProgressID);
}


export {
    createApp,
    CONTAINER_ID,
    TakeScreen,
    TweenCamera,
    SetSceenScale,
    SetColor,
    setPoint,
    setLight,
    LoadProduct,
    updateScene,
    loadProductToScene,
    unLoadProductFromScene
};